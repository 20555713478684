const data = require('../../ejs/data.json')
const links = data.link
const bp = data.breakpoint //ブレイクポイント
const btn = document.querySelectorAll('.btn a') //ボタン要素の配列
const setURL = () => {
  const w = window.innerWidth
  let url = (w > bp)? links.pc : links.sp
  btn.forEach( e => {
    e.href = url
  })
}

setURL()

window.onresize = () => {
  setTimeout(()=>{
    setURL()
  }, 800)
}
